<template>
  <v-row class="pa-3">
    <v-col xs12>
      <v-card class="pb-2">
        <v-card-title>
          <label class="font-weight-bold grey--text text--lighten-1 body-2"
            >DATA RIWAYAT PEMERIKSAAN LABORATURIUM</label
          >
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            tile
            color="red"
            v-if="filter_open"
            class="mx-0 white--text"
            @click="reset()"
            >RESET</v-btn
          >
          <v-btn
            depressed
            color="#F4F5F5"
            @click="filterOpen()"
            tile
            class="mx-3"
          >
            <img src="@/assets/image/ic_filter.png" class="mx-2" />
            <span class="black--text text-capitalize">Filters</span>
          </v-btn>

          <v-text-field
            placeholder="Cari jenis pemeriksaan"
            solo
            flat
            prepend-inner-icon="mdi-magnify"
            hide-details
            class="search pt-1"
            v-model="search"
          />
        </v-card-title>
        <v-row v-if="filter_open" class="py-2 px-3">
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Tanggal Pemeriksaan</label>
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="datepicker"
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="date.formatted"
                  v-on="on"
                  readonly
                  placeholder="Tanggal"
                  outlined
                  dense
                  hide-details="false"
                  single-line
                ></v-text-field>
              </template>
              <v-date-picker
                multiple
                v-model="selectedDate"
                @input="changedate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col align-self-center class="pt-8 px-0" style="max-width: 30px">
            <v-btn icon class="ma-0" @click="selectSort">
              <v-icon>mdi-swap-vertical</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Unit</label>
            <v-combobox
              placeholder="Semua Unit"
              outlined
              dense
              hide-details
              single-line
              :items="clinic"
              item-text="name"
              v-model="unitFilter"
              @change="getLab()"
              clearable
            />
          </v-col>
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Dokter</label>
            <v-combobox
              placeholder="Semua Dokter"
              outlined
              dense
              hide-details
              single-line
              :items="doctor"
              item-text="name"
              v-model="doctorFilter"
              @change="getLab()"
              clearable
            />
          </v-col>
        </v-row>

        <template v-for="(lab, index) in resource.labHistory">
          <v-card
            flat
            class="pa-3 ma-3 text-xs-left"
            style="border: 0.5px solid #429DDB; border-radius: 5px;"
            :key="'labhist-' + index"
          >
            <v-row>
              <v-col class="text-left" xs4>
                <h3>{{ lab.orderDoctor }}</h3>
                <div>
                  <v-btn
                    class="text-none ml-0 mr-2 caption font-weight-medium px-1 unit-btn"
                    outlined
                    depressed
                    style="background-color: #F8F2FF; border: 1px solid #c592fc"
                    color="#9B51E0"
                  >
                    {{ lab.unit }}
                  </v-btn>
                  <!-- nomor pemeriksaan belum ada -->

                  <v-btn
                    class="text-none mx-0 caption px-1 font-weight-medium number-btn"
                    outlined
                    depressed
                    style="background-color: #F0F9FF; border: 1px solid #4492c7"
                    color="#2F80ED"
                    >{{ lab.orderNumber }}
                  </v-btn>
                </div>
                <div style="display: grid;">
                  <label class="caption grey--text"
                    >Tanggal Pemeriksaan :</label
                  >
                  <label class="font-weight-medium caption">{{
                    lab.schedule
                  }}</label>
                </div>
              </v-col>
              <v-col xs5 class="pt-4">
                <div class="examination">
                  <label class="caption grey--text">Jenis Pemeriksaan</label>
                  <template
                    v-for="(intervention, indexInter) in lab.intervention"
                  >
                    <span
                      v-if="indexInter % 2 === 0 && indexInter !== 0"
                      :key="'span-' + indexInter"
                    ></span>
                    <ul :key="'inter-' + indexInter">
                      <li class="caption text-left font-weight-medium">
                        {{ intervention }}
                      </li>
                    </ul>
                  </template>
                </div>
              </v-col>

              <v-col xs3 class="pt-4 text-left">
                <div style="display: grid;" class="pb-3">
                  <label class="caption grey--text"
                    >Dokter Laboratorium :</label
                  >
                  <label class="font-weight-medium caption">{{
                    lab.labDoctor
                  }}</label>
                </div>
                <div style="display: grid;">
                  <!-- <label class="caption grey--text">Lab Terkait :</label>
                  <template v-for="(ref, indexRef) in lab.referralLab">
                    <label
                      class="font-weight-medium caption"
                      :key="'ref-' + indexRef"
                      >{{ ref }}</label
                    >
                  </template> -->
                </div>
              </v-col>
              <v-col xs2 class="d-flex justify-end align-end">
                <v-tooltip bottom>
                  <v-btn
                    outlined
                    elevation="0"
                    class="btn-icon"
                    slot="activator"
                    :loading="
                      resource.labHistory[index].loading.loadingDownload
                    "
                    @click="printResult(true, lab.idLab, index)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <span>Download</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    elevation="0"
                    outlined
                    class="btn-icon ml-3"
                    slot="activator"
                    :loading="resource.labHistory[index].loading.loadingPrint"
                    @click="printResult(false, lab.idLab, index)"
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <span>Print</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </template>

        <div v-if="resource.labHistory.length === 0">
          <v-alert :value="true" type="info" class="white--text mx-3">
            Maaf data riwayat pemeriksaan laboratorium pasien tidak ditemukan
          </v-alert>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import 'jspdf-autotable';
import formatMixin from '@/mixin/formatMixin';
import printMixin from '@/mixin/printMixin';
import printLaboratoryResult from '@/mixin/printLaboratoryResult.js';
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: labGetters,
  mapActions: labActions,
  mapMutations: labMutations,
} = createNamespacedHelpers('ExaminationLaboratory');

const axios = require('axios');
const _ = require('lodash');

export default {
  mixins: [printMixin, formatMixin, printLaboratoryResult],
  props: {
    idPatient: {
      type: String,
    },
    id_emr: {
      type: String,
    },
    clinic: {
      type: Array,
    },
    doctor: {
      type: Array,
    },
    patientData: {
      type: Object,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      filter_open: false,
      resource: {
        labHistory: [],
      },
      dateCheck: {
        start: [],
        finish: [],
      },
      selectedDate: [],
      itemsPrint: [],
      date: {
        raw: [],
        formatted: moment().format('DD-MM-YYYY'),
      },
      datePicker: false,
      unitFilter: '',
      doctorFilter: '',
      search: '',
      loading: [],
    };
  },
  computed: {
    ...labGetters(['getLabResultById']),
    labResult: {
      get() {
        return this.getLabResultById;
      },
    },
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  mounted() {
    this.getLab('');
  },
  methods: {
    ...labMutations(['setDataLab']),
    ...labActions(['resolveGetLabResult']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getLab(v.search);
    }, 650),
    filterOpen() {
      this.filter_open = !this.filter_open;
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.datepicker = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
      this.getLab();
    },
    getLab(search) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/laboratory/all/${this.id_emr}?startDate=${this.date
              .raw[0] || ''}&endDate=${this.date.raw[1] || ''}&unit=${
              this.unitFilter ? this.unitFilter.name : ''
            }&doctor=${
              this.doctorFilter ? this.doctorFilter.name : ''
            }&search=${search}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.labHistory = data.map((item, index) => {
            return {
              itemIndex: index,
              orderDoctor: item.staff,
              orderNumber: item.code,
              unit: item?.unit,
              schedule:
                item.date === '-'
                  ? '-'
                  : `${moment(item.date)
                      .locale('id')
                      .format('ll')} pada ${moment(item.date)
                      .locale('id')
                      .format('LT')} WIB`,
              intervention: item.test_item?.slice(0, 2),
              labDoctor:
                item.unit === 'Laboratorium' ? item.staff : item.doctor_lab,
              idLab: item?.id_lab,
              loading: {
                loadingDownload: false,
                loadingPrint: false,
              },
            };
          });
        });
    },
    printResult(isDownload, idLab, index) {
      if (isDownload) {
        this.resource.labHistory[index].loading = {
          loadingDownload: true,
          loadingPrint: false,
        };
      } else {
        this.resource.labHistory[index].loading = {
          loadingDownload: false,
          loadingPrint: true,
        };
      }
      this.setDataLab({ idLab: idLab });
      this.resolveGetLabResult().finally(() => {
        const {
          staff,
          clinical_notes,
          recommendation,
          test_item,
          timestamps,
          patient,
          assurance,
          code,
        } = this.labResult;
        const doc = this.printLabResult({
          doctor: staff.name,
          timeOrder: `${moment(timestamps.created_at)
            .locale('id')
            .format('dddd, Do MMMM YYYY, HH:mm:ss')}`,
          timeFinish: `${
            timestamps.finished_at
              ? moment(timestamps.finished_at)
                  .locale('id')
                  .format('dddd, Do MMMM YYYY, HH:mm:ss')
              : '-'
          }`,
          guarantorType: assurance,
          name: patient.name,
          address: this.formatAddress(patient.address),
          gender: patient.gender ? 'Laki - laki' : 'Perempuan',
          birthDateAge: patient.birth_date,
          noLabRM: code ? `${code} / ${patient?.medical_record_number}` : '-',
          testItem: test_item,
          clinicalNotes: clinical_notes,
          recommendation: recommendation,
          timestamps: timestamps.created_at,
        });

        // doc.viewerPreferences({ FitWindow: true }, true);
        if (isDownload) {
          doc.save('Hasil Laboratorium.pdf');
          this.resource.labHistory[index].loading = {
            loadingDownload: false,
            loadingPrint: false,
          };
        }
        if (!isDownload) {
          // window.open(URL.createObjectURL(doc.output('blob')));
          doc.autoPrint({ variant: 'non-conform' });
          this.resource.labHistory[index].loading = {
            loadingDownload: false,
            loadingPrint: false,
          };
          const hiddFrame = document.createElement('iframe');
          hiddFrame.style.position = 'fixed';
          hiddFrame.style.width = '1px';
          hiddFrame.style.height = '1px';
          hiddFrame.style.opacity = '0.01';
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent,
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  'print',
                  false,
                  null,
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = doc.output('bloburl');
          document.body.appendChild(hiddFrame);
        }
      });
    },
    reset() {
      this.date.raw = [];
      this.date.formatted = moment().format('DD-MM-YYYY');
      this.unitFilter = null;
      this.doctorFilter = null;
      this.getLab();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  border: 1px solid #9ca2a5;
  border-radius: 3px;
  height: 40px;
}

.btn-icon {
  border-color: #cdd2d7;
  height: 30px;
  min-width: 30px;
}

.examination {
  display: grid;
  grid-template-columns: 110px 130px 150px;
}

::v-deep .v-input__slot {
  min-height: 0px !important;
}

::v-deep .v-text-field.v-text-field--enclosed .v-input__append-inner,
.v-text-field.v-text-field--enclosed .v-input__append-outer,
.v-text-field.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field.v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 5px;
}

.unit-btn {
  color: #c592fc;
}

.number-btn {
  color: #4492c7;
}
</style>
